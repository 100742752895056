import ImageManager from '@/components/campaign/contentBuilder/imageUpload/imageUpload';

export default {
  name: 'ImageAssetsPage',
  components: {
    ImageManager
  },
  data() {
    return {};
  },

  methods: {},

  mounted() {}
};
