<template>
  <div id="imageAssetsPage">
    <!-- Header -->
    <div class="sm:flex sm:items-center mb-3">
      <div class="sm:flex-auto">
        <div class="text-2xl font-semibold text-gray-900">Image Assets</div>
      </div>
    </div>

    <ImageManager :isImagePage="true"></ImageManager>
  </div>
</template>
<style lang="scss" src="./imageAssetsPage.scss"></style>

<script>
import imageAssetsPageComponent from './imageAssetsPageComponent';
export default imageAssetsPageComponent;
</script>
